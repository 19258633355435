<div class="container">
    <img src="../../assets/pic.jpg">
    <article class="contact">
        <h1>Philippe Hamelin</h1>
        <h4>Front End Developer</h4>
        <span><fa-icon [icon]="faMail"></fa-icon><p>hamelinphilippe1&#64;gmail.com</p></span>
        <span><fa-icon [icon]="faMobile"></fa-icon><p>06 75 91 82 **</p></span>
    </article>
    <aside class="social">
        <span><fa-icon [icon]="faLinkedin"></fa-icon><a target="_blank" href="https://www.linkedin.com/in/philippe-hamelin-459733134">Linkedin</a></span>
        <span><fa-icon [icon]="faGithub"></fa-icon><a target="_blank" href="https://github.com/phamelin42">Github</a></span>
        <span><fa-icon [icon]="faGamepad"></fa-icon><a target="_blank" href="https://www.codingame.com/profile/6ab0a9d31090b3969b9cd27d62e694f56488504">CodinGame</a></span>
        <!-- <span><fa-icon [icon]="faGlobe"></fa-icon><a target="_blank" href="https://www.phamelin.fr">https://www.phamelin.fr</a></span> -->
    </aside>
</div>